// Code generated by tygo. DO NOT EDIT.

//////////
// source: props.go

/**
 * plan property keys
 */
export const NODE_TYPE = "Node Type";
export const ACTUAL_ROWS = "Actual Rows";
export const PLAN_ROWS = "Plan Rows";
export const ACTUAL_TOTAL_TIME = "Actual Total Time";
export const ACTUAL_LOOPS = "Actual Loops";
export const TOTAL_COST = "Total Cost";
export const PLANS_PROP = "Plans";
export const STARTUP_COST = "Startup Cost";
export const PLAN_WIDTH = "Plan Width";
export const ACTUAL_STARTUP_TIME = "Actual Startup Time";
export const HASH_CONDITION_PROP = "Hash Cond";
export const INDEX_CONDITION = "Index Cond";
export const TOTAL_RUNTIME = "Total Runtime";
/**
 * computed
 */
export const COMPUTED_TAGS_PROP = "*Tags";
export const COSTLIEST_NODE_PROP = "*Costliest Node (by cost)";
export const LARGEST_NODE_PROP = "*Largest Node (by rows)";
export const SLOWEST_NODE_PROP = "*Slowest Node (by duration)";
export const MAXIMUM_COSTS_PROP = "*Most Expensive Node (cost)";
export const MAXIMUM_ROWS_PROP = "*Largest Node (rows)";
export const MAXIMUM_DURATION_PROP = "*Slowest Node (time)";
export const ACTUAL_DURATION = "*Actual Duration";
export const ACTUAL_COST_PROP = "*Actual Cost";
export const PLANNER_ESTIMATE_FACTOR = "*Planner Row Estimate Factor";
export const PLANNER_ESTIMATE_DIRECTION = "*Planner Row Estimate Direction";
export const PARENT_RELATIONSHIP = "Parent Relationship";
export const SUBPLAN_NAME = "Subplan Name";
export const BATCHES = "Batches";
export const ARRAY_INDEX_KEY = "arrayIndex";
export const RELATION_NAME = "Relation Name";
export const SCHEMA = "Schema";
export const ALIAS = "Alias";
export const GROUP_KEY = "Group Key";
export const SORT_KEY = "Sort Key";
export const SORT_METHOD = "Sort Method";
export const SORT_SPACE_TYPE = "Sort Space Type";
export const SORT_SPACE_USED = "Sort Space Used";
export const JOIN_TYPE = "Join Type";
export const INDEX_NAME = "Index Name";
export const HASH_CONDITION = "Hash Cond";
export const PARALLEL_AWARE = "Parallel Aware";
export const WORKERS = "Workers";
export const WORKERS_PLANNED = "Workers Planned";
export const WORKERS_LAUNCHED = "Workers Launched";
export const SHARED_HIT_BLOCKS = "Shared Hit Blocks";
export const SHARED_READ_BLOCKS = "Shared Read Blocks";
export const SHARED_DIRTIED_BLOCKS = "Shared Dirtied Blocks";
export const SHARED_WRITTEN_BLOCKS = "Shared Written Blocks";
export const TEMP_READ_BLOCKS = "Temp Read Blocks";
export const TEMP_WRITTEN_BLOCKS = "Temp Written Blocks";
export const LOCAL_HIT_BLOCKS = "Local Hit Blocks";
export const LOCAL_READ_BLOCKS = "Local Read Blocks";
export const LOCAL_DIRTIED_BLOCKS = "Local Dirtied Blocks";
export const LOCAL_WRITTEN_BLOCKS = "Local Written Blocks";
export const IO_READ_TIME = "I/O Read Time";
export const IO_WRITE_TIME = "I/O Write Time";
export const OUTPUT = "Output";
export const HEAP_FETCHES = "Heap Fetches";
export const WAL_RECORDS = "WAL Records";
export const WAL_BYTES = "WAL Bytes";
export const WAL_FPI = "WAL FPI";
export const FULL_SORT_GROUPS = "Full-sort Groups";
export const PRE_SORTED_GROUPS = "Pre-sorted Groups";
export const PRESORTED_KEY = "Presorted Key";
export const HEAP_BLOCKS = "Heap Blocks";
export const NODE_ID = "nodeId";
export const EXCLUSIVE_DURATION = "*Duration (exclusive)";
export const EXCLUSIVE_COST = "*Cost (exclusive)";
export const ACTUAL_ROWS_REVISED = "*Actual Rows Revised";
export const PLAN_ROWS_REVISED = "*Plan Rows Revised";
export const ROWS_REMOVED_BY_FILTER = "Rows Removed by Filter";
export const ROWS_REMOVED_BY_JOIN_FILTER = "Rows Removed by Join Filter";
export const FILTER = "Filter";
export const JOIN_FILTER = "Join Filter";
export const WORKERS_PLANNED_BY_GATHER = "*Workers Planned By Gather";
export const CTE_SCAN = "CTE Scan";
export const CTE_NAME = "CTE Name";
export const CTES = "CTEs";
export const IS_CTE_ROOT = "*Is CTE Root";
export const CTE_SUBPLAN_OF = "*CTE Subplan Of";
export const FUNCTION_NAME = "Function Name";
export const PEV_PLAN_TAG = "plan_";
export const EstimateDirectionOver = "over";
export const EstimateDirectionUnder = "under";
export const EstimateDirectionNone = "none";
/**
 * Operations
 */
export const SEQUENTIAL_SCAN = "Seq Scan";
export const INDEX_SCAN = "Index Scan";
export const INDEX_ONLY_SCAN = "Index Only Scan";
export const BITMAP_INDEX_SCAN = "Bitmap Index Scan";
export const BITMAP_HEAP_SCAN = "Bitmap Heap Scan";
export const HASH = "Hash";
export const HASH_JOIN = "Hash Join";
export const HASH_AGGREGATE = "HashAggregate";
export const SORT = "Sort";
export const FUNCTION_SCAN = "Function Scan";
export const INCREMENTAL_SORT = "Incremental Sort";
export const NESTED_LOOP_JOIN = "Nested Loop Join";
export const NESTED_LOOP = "Nested Loop";
export const NESTED_LOOP_SEMI_JOIN = "Nested Loop Semi Join";
export const MERGE_JOIN = "Merge Join";
export const GROUP_AGGREGATE = "GroupAggregate";
export const X_POSITION_FACTOR = "*X Position Factor";
export const Y_POSITION_FACTOR = "*Y Position Factor";
export const EXCLUSIVE = "Exclusive ";
export const REVISED = " Revised";
export const DOES_CONTAIN_BUFFERS = "Does contain buffers";

//////////
// source: types.go

export type Node = { [key: string]: any};
export interface JIT {
  Functions: number /* int */;
  Options: {
    Inlining: boolean;
    Optimization: boolean;
    Expressions: boolean;
    Deforming: boolean;
  };
  Timing: {
    Generation: number /* float64 */;
    Inlining: number /* float64 */;
    Optimization: number /* float64 */;
    Emission: number /* float64 */;
    Total: number /* float64 */;
  };
}
/**
 * StatsFromPlan Statistic can be found in different forms
 */
export interface StatsFromPlan {
  plan: {
    'Execution Time': number /* float64 */;
    'Planning Time': number /* float64 */;
    JIT?: JIT;
  };
  'Execution Time': number /* float64 */;
  'Planning Time': number /* float64 */;
  JIT?: JIT;
  Triggers?: {
    'Trigger Name': string;
    Time: number /* float64 */;
    Calls: string;
  }[];
}
export interface Stats {
  execution_time: number /* float64 */;
  planning_time: number /* float64 */;
  max_rows: number /* float64 */;
  max_duration: number /* float64 */;
  max_cost: number /* float64 */;
  max_blocks_read: number /* float64 */;
  max_blocks_written: number /* float64 */;
  max_blocks_hit: number /* float64 */;
}
export type Plans = {
    Plan: { [key: string]: any};
  }[];
export interface IndexesStats {
  stats: IndexStats[];
}
export interface TablesStats {
  stats: TableStats[];
}
export interface NodesStats {
  stats: NodeStats[];
}
export interface Explained {
  summary: PlanRow[];
  stats: Stats;
  indexes_stats: IndexesStats;
  tables_stats: TablesStats;
  nodes_stats: NodesStats;
  jit_stats?: JIT;
  triggers_stats?: Triggers;
}
export interface NodeScopes {
  table: string;
  filters: string;
  index: string;
  key: string;
  condition: string;
}
export interface Costs {
  startup_cost: number /* float64 */;
  total_cost: number /* float64 */;
  plan_width: number /* float64 */;
}
export interface Rows {
  total: number /* float64 */;
  total_avg: number /* float64 */;
  planned_rows: number /* float64 */;
  removed: number /* float64 */;
  estimation_factor: number /* float64 */;
  estimation_direction: string;
}
export interface Buffers {
  reads: number /* float64 */;
  written: number /* float64 */;
  hits: number /* float64 */;
  dirtied: number /* float64 */;
  local_reads: number /* float64 */;
  local_written: number /* float64 */;
  local_hits: number /* float64 */;
  local_dirtied: number /* float64 */;
  temp_reads: number /* float64 */;
  temp_written: number /* float64 */;
  exclusive_reads: number /* float64 */;
  exclusive_written: number /* float64 */;
  exclusive_hits: number /* float64 */;
  exclusive_dirtied: number /* float64 */;
  exclusive_temp_reads: number /* float64 */;
  exclusive_temp_written: number /* float64 */;
  exclusive_local_reads: number /* float64 */;
  exclusive_local_written: number /* float64 */;
  exclusive_local_hits: number /* float64 */;
  exclusive_local_dirtied: number /* float64 */;
  effective_blocks_read: number /* float64 */;
  effective_blocks_written: number /* float64 */;
  effective_blocks_hits: number /* float64 */;
}
export interface Worker {
  number: number /* float64 */;
  loops: number /* float64 */;
  rows: number /* float64 */;
  time: number /* float64 */;
}
export interface Workers {
  launched: number /* float64 */;
  planned: number /* float64 */;
  list: Property[][];
}
export interface Timings {
  inclusive: number /* float64 */;
  exclusive: number /* float64 */;
  execution_time: number /* float64 */;
}
export interface PlanRow {
  node_id: string;
  node_short_id: number /* float64 */;
  node_fingerprint: string;
  node_parent_id: string;
  operation: string;
  level: number /* int */;
  scopes: NodeScopes;
  inclusive: number /* float64 */;
  timings: Timings;
  loops: number /* float64 */;
  rows: Rows;
  costs: Costs;
  exclusive: number /* float64 */;
  execution_time: number /* float64 */;
  buffers: Buffers;
  sub_plan_of: string;
  cte_sub_plan_of: string;
  parent_plan_id: string;
  does_contain_buffers: boolean;
  workers: Workers;
  node_type_specific_properties: Property[];
}
export interface Operation {
  relation_name: string;
  index: string;
  filter: string;
  key: string;
  condition: string;
}
export interface Scope {
  name: string;
  prepend: string;
}
export interface IndexNode {
  id: string;
  type: string;
  exclusive_time: number /* float64 */;
  condition: string;
}
export interface TableNode {
  id: string;
  type: string;
  exclusive_time: number /* float64 */;
}
export interface IndexStats {
  nodes: IndexNode[];
  total_time: number /* float64 */;
  percentage: number /* float64 */;
  name: string;
}
export interface TableStats {
  nodes: TableNode[];
  total_time: number /* float64 */;
  percentage: number /* float64 */;
  name: string;
}
export interface NodeStats {
  nodes: NodeNode[];
  total_time: number /* float64 */;
  percentage: number /* float64 */;
  name: string;
}
export interface NodeNode {
  id: string;
  type: string;
  exclusive_time: number /* float64 */;
}
export interface Property {
  id: string;
  name: string;
  type: string;
  float: number /* float64 */;
  string: string;
  skip: boolean;
  kind: Kind;
}
export type Kind = string;
export interface ExplainedComparison {
  query: string;
}
export interface ComparisonGeneralStats {
  execution_time: PropComparison;
  planning_time: PropComparison;
  max_rows: PropComparison;
  max_duration: PropComparison;
  max_cost: PropComparison;
  max_blocks_read: PropComparison;
  max_blocks_written: PropComparison;
  max_blocks_hit: PropComparison;
}
export interface NodeComparison {
  node_id: string;
  node_id_to_compare: string;
  operation: string;
  level: number /* int */;
  warnings: string[];
  infos: string[];
  scopes: NodeScopesComparison;
  inclusive_time: PropComparison;
  loops: PropComparison;
  rows: RowsComparison;
  costs: CostsComparison;
  exclusive_time: PropComparison;
  execution_time: PropComparison;
  buffers: BuffersComparison;
}
export interface NodeScopesComparison {
  filters: PropStringComparison;
  index: PropStringComparison;
  key: PropStringComparison;
  condition: PropStringComparison;
}
export interface RowsComparison {
  total_rows: PropComparison;
  planned_rows: PropComparison;
  removed_rows: PropComparison;
  rows_estimation_factor: PropComparison;
}
export interface BuffersComparison {
  effective_blocks_read: PropComparison;
  effective_blocks_written: PropComparison;
  effective_blocks_hits: PropComparison;
}
export interface CostsComparison {
  startup_cost: PropComparison;
  total_cost: PropComparison;
  plan_width: PropComparison;
}
export interface Comparison {
  general_stats: ComparisonGeneralStats;
}
export interface PropComparison {
  current: number /* float64 */;
  to_compare: number /* float64 */;
  has_improved: boolean;
  percentage_improved: number /* float64 */;
}
export interface PropStringComparison {
  original: string;
  to_compare: string;
  are_same: boolean;
}
export interface Trigger {
  name: string;
  time: number /* float64 */;
  calls: number /* float64 */;
  avg_time: number /* float64 */;
}
export interface Triggers {
  max_time: number /* float64 */;
  items: Trigger[];
}
export interface ExplainedError {
  error: string;
  error_details: string;
  error_stack: string;
}
export interface ExplainedResponse {
  error: string;
  explained: string;
}
export interface ComparisonResponse {
  error: string;
  comparison: string;
}
